import { CircularLoading } from '@/components/loading/CircularLoading';
import { useValue } from '@/lib/composables';
import { useRoute, useRouter } from '@/lib/composables/utils/useRouter';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { AgendamentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { AgendamentoStatus, IAgendamentoFragment } from '@/typings';
import { computed, onMounted, Ref, SetupContext } from '@vue/composition-api';
import { AuthCard } from '../components/AuthCard';

// não encontrou agendamento
// confirmado = confirmado agendamento
// não confirmado agendamento
// cancelar agendamento
// cancelado agendamento
// não cancelado agendamento

export default createComponent({
  name: 'ConfirmarAgendamentoPage',
  setup(props, ctx) {
    const [$loading, setLoading] = useValue(true);

    const [$submitting, setSubmitting] = useValue(false);

    const [$agendamento, setAgendamento] = useValue<
      IAgendamentoFragment | null | undefined
    >(null);

    const { token } = useRoute().params;

    const {
      $isCancelamentoRoute,
      $success,
      $submitted,
      $title,
      $agendamentoDesc,
      $isCancelamento,
    } = useComputeds({ $agendamento, ctx });

    const { handleCancel } = useEvents({
      token,
      ctx,
      $agendamento,
      $isCancelamentoRoute,
      setLoading,
      setSubmitting,
      setAgendamento,
    });

    return () => (
      <AuthCard>
        <div class="flex items-center justify-center">
          <CircularLoading loading={$loading.value}>
            {$agendamento.value ? (
              agendamentoMessage({
                $title,
                $agendamentoDesc,
                $submitted,
                $submitting,
                $success,
                $isCancelamento,
                $isCancelamentoRoute,
                handleCancel,
              })
            ) : (
              <Confirmacao />
            )}
          </CircularLoading>
        </div>
      </AuthCard>
    );
  },
});

function useComputeds({
  ctx,
  $agendamento,
}: {
  ctx: SetupContext;
  $agendamento: Ref<IAgendamentoFragment | null | undefined>;
}) {
  const $isCancelamentoRoute = computed(() =>
    useRoute().path.includes('cancelar'),
  );

  const $success = computed(() => {
    const agendamento = $agendamento.value;

    if (!agendamento || !agendamento.status) {
      return false;
    }

    const statusConfirmado = [
      AgendamentoStatus.AGUARDANDO_ATENDIMENTO,
      AgendamentoStatus.CONFIRMADO,
      AgendamentoStatus.PACIENTE_ATENDIDO,
    ];
    const statusCancelado = [
      AgendamentoStatus.CANCELADO,
      AgendamentoStatus.NAO_COMPARECEU,
    ];

    return $isCancelamentoRoute.value
      ? statusCancelado.includes(agendamento.status)
      : statusConfirmado.includes(agendamento.status);
  });

  const $submitted = computed(() => {
    const agendamento = $agendamento.value;

    if (!agendamento || !agendamento.status) {
      return false;
    }

    const status = [
      AgendamentoStatus.AGUARDANDO_ATENDIMENTO,
      AgendamentoStatus.CONFIRMADO,
      AgendamentoStatus.PACIENTE_ATENDIDO,
      AgendamentoStatus.CANCELADO,
      AgendamentoStatus.NAO_COMPARECEU,
    ];

    return !$isCancelamentoRoute.value || status.includes(agendamento.status);
  });

  const $title = computed(() => {
    const confirmado = 'Agendamento confirmado';
    const naoConfirmado = 'Agendamento não confirmado';
    const cancelar = 'Confirmação de cancelamento';
    const cancelado = 'Agendamento cancelado';
    const naoCancelado = 'Agendamento não cancelado';

    if (!$submitted.value) return cancelar;

    if ($success.value) {
      return $isCancelamento.value ? cancelado : confirmado;
    }

    return $isCancelamento.value ? naoCancelado : naoConfirmado;
  });

  const $agendamentoDesc = computed(() => {
    const agendamento = $agendamento.value;

    if (!agendamento) return '';

    const profissional = agendamento.profissional.nome;
    const data = DateHelpers.formatDate(agendamento.data);
    const hora = DateHelpers.formatHour(agendamento.horaInicial);

    return `agendamento com ${profissional}, dia ${data} às ${hora}`;
  });

  const $isCancelamento = computed(() => {
    const agendamento = $agendamento.value;

    if (!$success.value || !agendamento || !agendamento.status) {
      return useRoute().path.includes('cancelar');
    }

    const statusCancelados = [
      AgendamentoStatus.CANCELADO,
      AgendamentoStatus.NAO_COMPARECEU,
    ];

    return statusCancelados.includes(agendamento.status);
  });

  return {
    $isCancelamentoRoute,
    $success,
    $submitted,
    $title,
    $agendamentoDesc,
    $isCancelamento,
  };
}

function useEvents({
  token,
  ctx,
  $agendamento,
  $isCancelamentoRoute,
  setLoading,
  setSubmitting,
  setAgendamento,
}: {
  token: string;
  ctx: SetupContext;
  $agendamento: Ref<IAgendamentoFragment | null | undefined>;
  $isCancelamentoRoute: Ref<boolean>;
  setLoading: (v: boolean) => void;
  setSubmitting: (v: boolean) => void;
  setAgendamento: (v: IAgendamentoFragment | null | undefined) => void;
}) {
  async function handleCancel() {
    try {
      if (!$agendamento.value) {
        return;
      }

      setSubmitting(true);

      setAgendamento(
        await AgendamentoService.confirmacaoCancelAgendamento(token),
      );
    } catch (error) {
      console.error(error);
    }
  }

  onMounted(async () => {
    if (!token) {
      return useRouter().replace(Routes.auth.login);
    }

    setAgendamento(
      await AgendamentoService.confirmacaoCheckAgendamento({
        confirmacaoToken: token,
        confirm: !$isCancelamentoRoute.value,
      }),
    );

    setLoading(false);
  });

  return { handleCancel };
}

const agendamentoMessage = ({
  $title,
  $agendamentoDesc,
  $submitted,
  $submitting,
  $success,
  $isCancelamento,
  $isCancelamentoRoute,
  handleCancel,
}: {
  $title: Ref<string>;
  $agendamentoDesc: Ref<string>;
  $submitted: Ref<boolean>;
  $submitting: Ref<boolean>;
  $success: Ref<boolean>;
  $isCancelamento: Ref<boolean>;
  $isCancelamentoRoute: Ref<boolean>;
  handleCancel: () => Promise<void>;
}) => (
  <div class="flex flex-col">
    <h1 class="text-center text-headline text-primary">{$title.value}</h1>

    {message({
      $agendamentoDesc,
      $submitted,
      $success,
      $isCancelamento,
      $isCancelamentoRoute,
    })}

    {!$submitted.value && $isCancelamentoRoute.value && (
      <v-btn
        color="error"
        loading={$submitting.value}
        disabled={$submitting.value}
        onClick={handleCancel}
      >
        Cancelar
      </v-btn>
    )}
  </div>
);

const message = ({
  $agendamentoDesc,
  $submitted,
  $success,
  $isCancelamento,
  $isCancelamentoRoute,
}: {
  $agendamentoDesc: Ref<string>;
  $submitted: Ref<boolean>;
  $success: Ref<boolean>;
  $isCancelamento: Ref<boolean>;
  $isCancelamentoRoute: Ref<boolean>;
}) => {
  const classes = 'text-subtitle text-center text-gray-600 py-4';

  const agendamentoDesc = $agendamentoDesc.value;

  const confirmado = (
    <div class={classes}>
      Seu {agendamentoDesc}, foi{' '}
      <b class="text-success">confirmado com sucesso</b>!
    </div>
  );

  const naoConfirmado = (
    <div class={classes}>
      Infelizmente não pudemos concluir a confirmação de seu {agendamentoDesc}.
      Se você já cancelou esse agendamento anteriormente, não poderá confirmá-lo
      depois.
    </div>
  );

  const cancelar = (
    <div class={classes}>Deseja cancelar o {agendamentoDesc}?</div>
  );

  const cancelado = (
    <div class={classes}>
      Seu {agendamentoDesc}, foi <b class="text-error">cancelado com sucesso</b>
      !
    </div>
  );

  const naoCancelado = (
    <div class={classes}>
      Infelizmente não pudemos concluir o cancelamento de seu {agendamentoDesc}.
    </div>
  );

  if (!$submitted.value) {
    return cancelar;
  }

  if ($success.value) {
    return $isCancelamento.value ? cancelado : confirmado;
  }

  return $isCancelamentoRoute.value ? naoCancelado : naoConfirmado;
};

const Confirmacao = () => (
  <div>
    <h1 class="text-center text-headline text-error">
      Link para confirmar agendamento já expirou
    </h1>
  </div>
);
